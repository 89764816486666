/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
h1,
h2 {
  font-family: "Inter", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
  color: var(--ion-color-dark);
}
h1 small,
h2 small {
  display: block;
  font-size: 0.6em;
  opacity: 0.5;
  line-height: 1.8;
}

h3 {
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--ion-color-dark);
}

h4 {
  color: var(--ion-color-dark);
}

h5 {
  color: var(--ion-color-dark);
}

h6 {
  color: var(--ion-color-dark);
}

p {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  opacity: 0.8;
  line-height: 1.4;
  color: var(--ion-color-dark);
}
p.sm {
  font-size: 1.4rem;
}

span {
  color: var(--ion-color-dark);
}

.font-source {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.fw-1 {
  font-weight: 100;
}

.fw-2 {
  font-weight: 200;
}

.fw-3 {
  font-weight: 300;
}

.fw-4 {
  font-weight: 400;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-8 {
  font-weight: 800;
}

.fw-9 {
  font-weight: 900;
}

.color-primary {
  color: var(--ion-color-primary);
}
.color-secondary {
  color: var(--ion-color-secondary);
}
.color-tertiary {
  color: var(--ion-color-tertiary);
}
.color-danger {
  color: var(--ion-color-danger);
}
.color-success {
  color: var(--ion-color-success);
}
.color-warning {
  color: var(--ion-color-warning);
}
.color-dark {
  color: var(--ion-color-dark);
}
.color-medium {
  color: var(--ion-color-medium);
}
.color-light {
  color: var(--ion-color-light);
}

.bg-primary {
  --background: var(--ion-color-primary);
  background: var(--ion-color-primary);
}
.bg-secondary {
  --background: var(--ion-color-secondary);
  background: var(--ion-color-secondary);
}
.bg-tertiary {
  --background: var(--ion-color-tertiary);
  background: var(--ion-color-tertiary);
}
.bg-danger {
  --background: var(--ion-color-danger);
  background: var(--ion-color-danger);
}
.bg-success {
  --background: var(--ion-color-success);
  background: var(--ion-color-success);
}
.bg-warning {
  --background: var(--ion-color-warning);
  background: var(--ion-color-warning);
}
.bg-dark {
  --background: var(--ion-color-dark);
  background: var(--ion-color-dark);
}
.bg-medium {
  --background: var(--ion-color-medium);
  background: var(--ion-color-medium);
}
.bg-light {
  --background: var(--ion-color-light);
  background: var(--ion-color-light);
}
.bg-transparent {
  --background: transparent;
  background: transparent;
}

.opacity-1 {
  opacity: 0.1;
}

.bg-primary-1 {
  --background: rgba(var(--ion-color-primary-rgb), 0.1);
  background: rgba(var(--ion-color-primary-rgb), 0.1);
}
.bg-secondary-1 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.1);
  background: rgba(var(--ion-color-secondary-rgb), 0.1);
}
.bg-tertiary-1 {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.1);
  background: rgba(var(--ion-color-tertiary-rgb), 0.1);
}
.bg-success-1 {
  --background: rgba(var(--ion-color-success-rgb), 0.1);
  background: rgba(var(--ion-color-success-rgb), 0.1);
}
.bg-warning-1 {
  --background: rgba(var(--ion-color-warning-rgb), 0.1);
  background: rgba(var(--ion-color-warning-rgb), 0.1);
}
.bg-danger-1 {
  --background: rgba(var(--ion-color-danger-rgb), 0.1);
  background: rgba(var(--ion-color-danger-rgb), 0.1);
}
.bg-dark-1 {
  --background: rgba(var(--ion-color-dark-rgb), 0.1);
  background: rgba(var(--ion-color-dark-rgb), 0.1);
}
.bg-medium-1 {
  --background: rgba(var(--ion-color-medium-rgb), 0.1);
  background: rgba(var(--ion-color-medium-rgb), 0.1);
}
.bg-light-1 {
  --background: rgba(var(--ion-color-light-rgb), 0.1);
  background: rgba(var(--ion-color-light-rgb), 0.1);
}

.color-primary-1 {
  color: rgba(var(--ion-color-primary), 0.1);
}
.color-secondary-1 {
  color: rgba(var(--ion-color-secondary), 0.1);
}
.color-tertiary-1 {
  color: rgba(var(--ion-color-tertiary), 0.1);
}
.color-danger-1 {
  color: rgba(var(--ion-color-danger), 0.1);
}
.color-success-1 {
  color: rgba(var(--ion-color-success), 0.1);
}
.color-warning-1 {
  color: rgba(var(--ion-color-warning), 0.1);
}
.color-dark-1 {
  color: rgba(var(--ion-color-dark), 0.1);
}
.color-medium-1 {
  color: rgba(var(--ion-color-medium), 0.1);
}
.color-light-1 {
  color: rgba(var(--ion-color-light), 0.1);
}

.outline-dark-1 {
  border-color: rgba(var(--ion-color-dark), 0.1);
}

.outline-danger-1 {
  border-color: rgba(var(--ion-color-danger-rgb), 0.1);
}

.outline-success-1 {
  border-color: rgba(var(--ion-color-success-rgb), 0.1);
}

.outline-tertiary-1 {
  border-color: rgba(var(--ion-color-tertiary-rgb), 0.1);
}

.outline-medium-1 {
  border-color: rgba(var(--ion-color-medium-rgb), 0.1);
}

.outline-danger {
  border-color: var(--ion-color-danger);
}

.outline-primary {
  border-color: var(--ion-color-primary);
}

.opacity-2 {
  opacity: 0.2;
}

.bg-primary-2 {
  --background: rgba(var(--ion-color-primary-rgb), 0.2);
  background: rgba(var(--ion-color-primary-rgb), 0.2);
}
.bg-secondary-2 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
  background: rgba(var(--ion-color-secondary-rgb), 0.2);
}
.bg-tertiary-2 {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.2);
  background: rgba(var(--ion-color-tertiary-rgb), 0.2);
}
.bg-success-2 {
  --background: rgba(var(--ion-color-success-rgb), 0.2);
  background: rgba(var(--ion-color-success-rgb), 0.2);
}
.bg-warning-2 {
  --background: rgba(var(--ion-color-warning-rgb), 0.2);
  background: rgba(var(--ion-color-warning-rgb), 0.2);
}
.bg-danger-2 {
  --background: rgba(var(--ion-color-danger-rgb), 0.2);
  background: rgba(var(--ion-color-danger-rgb), 0.2);
}
.bg-dark-2 {
  --background: rgba(var(--ion-color-dark-rgb), 0.2);
  background: rgba(var(--ion-color-dark-rgb), 0.2);
}
.bg-medium-2 {
  --background: rgba(var(--ion-color-medium-rgb), 0.2);
  background: rgba(var(--ion-color-medium-rgb), 0.2);
}
.bg-light-2 {
  --background: rgba(var(--ion-color-light-rgb), 0.2);
  background: rgba(var(--ion-color-light-rgb), 0.2);
}

.color-primary-2 {
  color: rgba(var(--ion-color-primary), 0.2);
}
.color-secondary-2 {
  color: rgba(var(--ion-color-secondary), 0.2);
}
.color-tertiary-2 {
  color: rgba(var(--ion-color-tertiary), 0.2);
}
.color-danger-2 {
  color: rgba(var(--ion-color-danger), 0.2);
}
.color-success-2 {
  color: rgba(var(--ion-color-success), 0.2);
}
.color-warning-2 {
  color: rgba(var(--ion-color-warning), 0.2);
}
.color-dark-2 {
  color: rgba(var(--ion-color-dark), 0.2);
}
.color-medium-2 {
  color: rgba(var(--ion-color-medium), 0.2);
}
.color-light-2 {
  color: rgba(var(--ion-color-light), 0.2);
}

.outline-dark-2 {
  border-color: rgba(var(--ion-color-dark), 0.2);
}

.outline-danger-2 {
  border-color: rgba(var(--ion-color-danger-rgb), 0.2);
}

.outline-success-2 {
  border-color: rgba(var(--ion-color-success-rgb), 0.2);
}

.outline-tertiary-2 {
  border-color: rgba(var(--ion-color-tertiary-rgb), 0.2);
}

.outline-medium-2 {
  border-color: rgba(var(--ion-color-medium-rgb), 0.2);
}

.outline-danger {
  border-color: var(--ion-color-danger);
}

.outline-primary {
  border-color: var(--ion-color-primary);
}

.opacity-3 {
  opacity: 0.3;
}

.bg-primary-3 {
  --background: rgba(var(--ion-color-primary-rgb), 0.3);
  background: rgba(var(--ion-color-primary-rgb), 0.3);
}
.bg-secondary-3 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.3);
  background: rgba(var(--ion-color-secondary-rgb), 0.3);
}
.bg-tertiary-3 {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.3);
  background: rgba(var(--ion-color-tertiary-rgb), 0.3);
}
.bg-success-3 {
  --background: rgba(var(--ion-color-success-rgb), 0.3);
  background: rgba(var(--ion-color-success-rgb), 0.3);
}
.bg-warning-3 {
  --background: rgba(var(--ion-color-warning-rgb), 0.3);
  background: rgba(var(--ion-color-warning-rgb), 0.3);
}
.bg-danger-3 {
  --background: rgba(var(--ion-color-danger-rgb), 0.3);
  background: rgba(var(--ion-color-danger-rgb), 0.3);
}
.bg-dark-3 {
  --background: rgba(var(--ion-color-dark-rgb), 0.3);
  background: rgba(var(--ion-color-dark-rgb), 0.3);
}
.bg-medium-3 {
  --background: rgba(var(--ion-color-medium-rgb), 0.3);
  background: rgba(var(--ion-color-medium-rgb), 0.3);
}
.bg-light-3 {
  --background: rgba(var(--ion-color-light-rgb), 0.3);
  background: rgba(var(--ion-color-light-rgb), 0.3);
}

.color-primary-3 {
  color: rgba(var(--ion-color-primary), 0.3);
}
.color-secondary-3 {
  color: rgba(var(--ion-color-secondary), 0.3);
}
.color-tertiary-3 {
  color: rgba(var(--ion-color-tertiary), 0.3);
}
.color-danger-3 {
  color: rgba(var(--ion-color-danger), 0.3);
}
.color-success-3 {
  color: rgba(var(--ion-color-success), 0.3);
}
.color-warning-3 {
  color: rgba(var(--ion-color-warning), 0.3);
}
.color-dark-3 {
  color: rgba(var(--ion-color-dark), 0.3);
}
.color-medium-3 {
  color: rgba(var(--ion-color-medium), 0.3);
}
.color-light-3 {
  color: rgba(var(--ion-color-light), 0.3);
}

.outline-dark-3 {
  border-color: rgba(var(--ion-color-dark), 0.3);
}

.outline-danger-3 {
  border-color: rgba(var(--ion-color-danger-rgb), 0.3);
}

.outline-success-3 {
  border-color: rgba(var(--ion-color-success-rgb), 0.3);
}

.outline-tertiary-3 {
  border-color: rgba(var(--ion-color-tertiary-rgb), 0.3);
}

.outline-medium-3 {
  border-color: rgba(var(--ion-color-medium-rgb), 0.3);
}

.outline-danger {
  border-color: var(--ion-color-danger);
}

.outline-primary {
  border-color: var(--ion-color-primary);
}

.opacity-4 {
  opacity: 0.4;
}

.bg-primary-4 {
  --background: rgba(var(--ion-color-primary-rgb), 0.4);
  background: rgba(var(--ion-color-primary-rgb), 0.4);
}
.bg-secondary-4 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.4);
  background: rgba(var(--ion-color-secondary-rgb), 0.4);
}
.bg-tertiary-4 {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.4);
  background: rgba(var(--ion-color-tertiary-rgb), 0.4);
}
.bg-success-4 {
  --background: rgba(var(--ion-color-success-rgb), 0.4);
  background: rgba(var(--ion-color-success-rgb), 0.4);
}
.bg-warning-4 {
  --background: rgba(var(--ion-color-warning-rgb), 0.4);
  background: rgba(var(--ion-color-warning-rgb), 0.4);
}
.bg-danger-4 {
  --background: rgba(var(--ion-color-danger-rgb), 0.4);
  background: rgba(var(--ion-color-danger-rgb), 0.4);
}
.bg-dark-4 {
  --background: rgba(var(--ion-color-dark-rgb), 0.4);
  background: rgba(var(--ion-color-dark-rgb), 0.4);
}
.bg-medium-4 {
  --background: rgba(var(--ion-color-medium-rgb), 0.4);
  background: rgba(var(--ion-color-medium-rgb), 0.4);
}
.bg-light-4 {
  --background: rgba(var(--ion-color-light-rgb), 0.4);
  background: rgba(var(--ion-color-light-rgb), 0.4);
}

.color-primary-4 {
  color: rgba(var(--ion-color-primary), 0.4);
}
.color-secondary-4 {
  color: rgba(var(--ion-color-secondary), 0.4);
}
.color-tertiary-4 {
  color: rgba(var(--ion-color-tertiary), 0.4);
}
.color-danger-4 {
  color: rgba(var(--ion-color-danger), 0.4);
}
.color-success-4 {
  color: rgba(var(--ion-color-success), 0.4);
}
.color-warning-4 {
  color: rgba(var(--ion-color-warning), 0.4);
}
.color-dark-4 {
  color: rgba(var(--ion-color-dark), 0.4);
}
.color-medium-4 {
  color: rgba(var(--ion-color-medium), 0.4);
}
.color-light-4 {
  color: rgba(var(--ion-color-light), 0.4);
}

.outline-dark-4 {
  border-color: rgba(var(--ion-color-dark), 0.4);
}

.outline-danger-4 {
  border-color: rgba(var(--ion-color-danger-rgb), 0.4);
}

.outline-success-4 {
  border-color: rgba(var(--ion-color-success-rgb), 0.4);
}

.outline-tertiary-4 {
  border-color: rgba(var(--ion-color-tertiary-rgb), 0.4);
}

.outline-medium-4 {
  border-color: rgba(var(--ion-color-medium-rgb), 0.4);
}

.outline-danger {
  border-color: var(--ion-color-danger);
}

.outline-primary {
  border-color: var(--ion-color-primary);
}

.opacity-5 {
  opacity: 0.5;
}

.bg-primary-5 {
  --background: rgba(var(--ion-color-primary-rgb), 0.5);
  background: rgba(var(--ion-color-primary-rgb), 0.5);
}
.bg-secondary-5 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.5);
  background: rgba(var(--ion-color-secondary-rgb), 0.5);
}
.bg-tertiary-5 {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.5);
  background: rgba(var(--ion-color-tertiary-rgb), 0.5);
}
.bg-success-5 {
  --background: rgba(var(--ion-color-success-rgb), 0.5);
  background: rgba(var(--ion-color-success-rgb), 0.5);
}
.bg-warning-5 {
  --background: rgba(var(--ion-color-warning-rgb), 0.5);
  background: rgba(var(--ion-color-warning-rgb), 0.5);
}
.bg-danger-5 {
  --background: rgba(var(--ion-color-danger-rgb), 0.5);
  background: rgba(var(--ion-color-danger-rgb), 0.5);
}
.bg-dark-5 {
  --background: rgba(var(--ion-color-dark-rgb), 0.5);
  background: rgba(var(--ion-color-dark-rgb), 0.5);
}
.bg-medium-5 {
  --background: rgba(var(--ion-color-medium-rgb), 0.5);
  background: rgba(var(--ion-color-medium-rgb), 0.5);
}
.bg-light-5 {
  --background: rgba(var(--ion-color-light-rgb), 0.5);
  background: rgba(var(--ion-color-light-rgb), 0.5);
}

.color-primary-5 {
  color: rgba(var(--ion-color-primary), 0.5);
}
.color-secondary-5 {
  color: rgba(var(--ion-color-secondary), 0.5);
}
.color-tertiary-5 {
  color: rgba(var(--ion-color-tertiary), 0.5);
}
.color-danger-5 {
  color: rgba(var(--ion-color-danger), 0.5);
}
.color-success-5 {
  color: rgba(var(--ion-color-success), 0.5);
}
.color-warning-5 {
  color: rgba(var(--ion-color-warning), 0.5);
}
.color-dark-5 {
  color: rgba(var(--ion-color-dark), 0.5);
}
.color-medium-5 {
  color: rgba(var(--ion-color-medium), 0.5);
}
.color-light-5 {
  color: rgba(var(--ion-color-light), 0.5);
}

.outline-dark-5 {
  border-color: rgba(var(--ion-color-dark), 0.5);
}

.outline-danger-5 {
  border-color: rgba(var(--ion-color-danger-rgb), 0.5);
}

.outline-success-5 {
  border-color: rgba(var(--ion-color-success-rgb), 0.5);
}

.outline-tertiary-5 {
  border-color: rgba(var(--ion-color-tertiary-rgb), 0.5);
}

.outline-medium-5 {
  border-color: rgba(var(--ion-color-medium-rgb), 0.5);
}

.outline-danger {
  border-color: var(--ion-color-danger);
}

.outline-primary {
  border-color: var(--ion-color-primary);
}

.opacity-6 {
  opacity: 0.6;
}

.bg-primary-6 {
  --background: rgba(var(--ion-color-primary-rgb), 0.6);
  background: rgba(var(--ion-color-primary-rgb), 0.6);
}
.bg-secondary-6 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.6);
  background: rgba(var(--ion-color-secondary-rgb), 0.6);
}
.bg-tertiary-6 {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.6);
  background: rgba(var(--ion-color-tertiary-rgb), 0.6);
}
.bg-success-6 {
  --background: rgba(var(--ion-color-success-rgb), 0.6);
  background: rgba(var(--ion-color-success-rgb), 0.6);
}
.bg-warning-6 {
  --background: rgba(var(--ion-color-warning-rgb), 0.6);
  background: rgba(var(--ion-color-warning-rgb), 0.6);
}
.bg-danger-6 {
  --background: rgba(var(--ion-color-danger-rgb), 0.6);
  background: rgba(var(--ion-color-danger-rgb), 0.6);
}
.bg-dark-6 {
  --background: rgba(var(--ion-color-dark-rgb), 0.6);
  background: rgba(var(--ion-color-dark-rgb), 0.6);
}
.bg-medium-6 {
  --background: rgba(var(--ion-color-medium-rgb), 0.6);
  background: rgba(var(--ion-color-medium-rgb), 0.6);
}
.bg-light-6 {
  --background: rgba(var(--ion-color-light-rgb), 0.6);
  background: rgba(var(--ion-color-light-rgb), 0.6);
}

.color-primary-6 {
  color: rgba(var(--ion-color-primary), 0.6);
}
.color-secondary-6 {
  color: rgba(var(--ion-color-secondary), 0.6);
}
.color-tertiary-6 {
  color: rgba(var(--ion-color-tertiary), 0.6);
}
.color-danger-6 {
  color: rgba(var(--ion-color-danger), 0.6);
}
.color-success-6 {
  color: rgba(var(--ion-color-success), 0.6);
}
.color-warning-6 {
  color: rgba(var(--ion-color-warning), 0.6);
}
.color-dark-6 {
  color: rgba(var(--ion-color-dark), 0.6);
}
.color-medium-6 {
  color: rgba(var(--ion-color-medium), 0.6);
}
.color-light-6 {
  color: rgba(var(--ion-color-light), 0.6);
}

.outline-dark-6 {
  border-color: rgba(var(--ion-color-dark), 0.6);
}

.outline-danger-6 {
  border-color: rgba(var(--ion-color-danger-rgb), 0.6);
}

.outline-success-6 {
  border-color: rgba(var(--ion-color-success-rgb), 0.6);
}

.outline-tertiary-6 {
  border-color: rgba(var(--ion-color-tertiary-rgb), 0.6);
}

.outline-medium-6 {
  border-color: rgba(var(--ion-color-medium-rgb), 0.6);
}

.outline-danger {
  border-color: var(--ion-color-danger);
}

.outline-primary {
  border-color: var(--ion-color-primary);
}

.opacity-7 {
  opacity: 0.7;
}

.bg-primary-7 {
  --background: rgba(var(--ion-color-primary-rgb), 0.7);
  background: rgba(var(--ion-color-primary-rgb), 0.7);
}
.bg-secondary-7 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.7);
  background: rgba(var(--ion-color-secondary-rgb), 0.7);
}
.bg-tertiary-7 {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.7);
  background: rgba(var(--ion-color-tertiary-rgb), 0.7);
}
.bg-success-7 {
  --background: rgba(var(--ion-color-success-rgb), 0.7);
  background: rgba(var(--ion-color-success-rgb), 0.7);
}
.bg-warning-7 {
  --background: rgba(var(--ion-color-warning-rgb), 0.7);
  background: rgba(var(--ion-color-warning-rgb), 0.7);
}
.bg-danger-7 {
  --background: rgba(var(--ion-color-danger-rgb), 0.7);
  background: rgba(var(--ion-color-danger-rgb), 0.7);
}
.bg-dark-7 {
  --background: rgba(var(--ion-color-dark-rgb), 0.7);
  background: rgba(var(--ion-color-dark-rgb), 0.7);
}
.bg-medium-7 {
  --background: rgba(var(--ion-color-medium-rgb), 0.7);
  background: rgba(var(--ion-color-medium-rgb), 0.7);
}
.bg-light-7 {
  --background: rgba(var(--ion-color-light-rgb), 0.7);
  background: rgba(var(--ion-color-light-rgb), 0.7);
}

.color-primary-7 {
  color: rgba(var(--ion-color-primary), 0.7);
}
.color-secondary-7 {
  color: rgba(var(--ion-color-secondary), 0.7);
}
.color-tertiary-7 {
  color: rgba(var(--ion-color-tertiary), 0.7);
}
.color-danger-7 {
  color: rgba(var(--ion-color-danger), 0.7);
}
.color-success-7 {
  color: rgba(var(--ion-color-success), 0.7);
}
.color-warning-7 {
  color: rgba(var(--ion-color-warning), 0.7);
}
.color-dark-7 {
  color: rgba(var(--ion-color-dark), 0.7);
}
.color-medium-7 {
  color: rgba(var(--ion-color-medium), 0.7);
}
.color-light-7 {
  color: rgba(var(--ion-color-light), 0.7);
}

.outline-dark-7 {
  border-color: rgba(var(--ion-color-dark), 0.7);
}

.outline-danger-7 {
  border-color: rgba(var(--ion-color-danger-rgb), 0.7);
}

.outline-success-7 {
  border-color: rgba(var(--ion-color-success-rgb), 0.7);
}

.outline-tertiary-7 {
  border-color: rgba(var(--ion-color-tertiary-rgb), 0.7);
}

.outline-medium-7 {
  border-color: rgba(var(--ion-color-medium-rgb), 0.7);
}

.outline-danger {
  border-color: var(--ion-color-danger);
}

.outline-primary {
  border-color: var(--ion-color-primary);
}

.opacity-8 {
  opacity: 0.8;
}

.bg-primary-8 {
  --background: rgba(var(--ion-color-primary-rgb), 0.8);
  background: rgba(var(--ion-color-primary-rgb), 0.8);
}
.bg-secondary-8 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.8);
  background: rgba(var(--ion-color-secondary-rgb), 0.8);
}
.bg-tertiary-8 {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.8);
  background: rgba(var(--ion-color-tertiary-rgb), 0.8);
}
.bg-success-8 {
  --background: rgba(var(--ion-color-success-rgb), 0.8);
  background: rgba(var(--ion-color-success-rgb), 0.8);
}
.bg-warning-8 {
  --background: rgba(var(--ion-color-warning-rgb), 0.8);
  background: rgba(var(--ion-color-warning-rgb), 0.8);
}
.bg-danger-8 {
  --background: rgba(var(--ion-color-danger-rgb), 0.8);
  background: rgba(var(--ion-color-danger-rgb), 0.8);
}
.bg-dark-8 {
  --background: rgba(var(--ion-color-dark-rgb), 0.8);
  background: rgba(var(--ion-color-dark-rgb), 0.8);
}
.bg-medium-8 {
  --background: rgba(var(--ion-color-medium-rgb), 0.8);
  background: rgba(var(--ion-color-medium-rgb), 0.8);
}
.bg-light-8 {
  --background: rgba(var(--ion-color-light-rgb), 0.8);
  background: rgba(var(--ion-color-light-rgb), 0.8);
}

.color-primary-8 {
  color: rgba(var(--ion-color-primary), 0.8);
}
.color-secondary-8 {
  color: rgba(var(--ion-color-secondary), 0.8);
}
.color-tertiary-8 {
  color: rgba(var(--ion-color-tertiary), 0.8);
}
.color-danger-8 {
  color: rgba(var(--ion-color-danger), 0.8);
}
.color-success-8 {
  color: rgba(var(--ion-color-success), 0.8);
}
.color-warning-8 {
  color: rgba(var(--ion-color-warning), 0.8);
}
.color-dark-8 {
  color: rgba(var(--ion-color-dark), 0.8);
}
.color-medium-8 {
  color: rgba(var(--ion-color-medium), 0.8);
}
.color-light-8 {
  color: rgba(var(--ion-color-light), 0.8);
}

.outline-dark-8 {
  border-color: rgba(var(--ion-color-dark), 0.8);
}

.outline-danger-8 {
  border-color: rgba(var(--ion-color-danger-rgb), 0.8);
}

.outline-success-8 {
  border-color: rgba(var(--ion-color-success-rgb), 0.8);
}

.outline-tertiary-8 {
  border-color: rgba(var(--ion-color-tertiary-rgb), 0.8);
}

.outline-medium-8 {
  border-color: rgba(var(--ion-color-medium-rgb), 0.8);
}

.outline-danger {
  border-color: var(--ion-color-danger);
}

.outline-primary {
  border-color: var(--ion-color-primary);
}

.opacity-9 {
  opacity: 0.9;
}

.bg-primary-9 {
  --background: rgba(var(--ion-color-primary-rgb), 0.9);
  background: rgba(var(--ion-color-primary-rgb), 0.9);
}
.bg-secondary-9 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.9);
  background: rgba(var(--ion-color-secondary-rgb), 0.9);
}
.bg-tertiary-9 {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.9);
  background: rgba(var(--ion-color-tertiary-rgb), 0.9);
}
.bg-success-9 {
  --background: rgba(var(--ion-color-success-rgb), 0.9);
  background: rgba(var(--ion-color-success-rgb), 0.9);
}
.bg-warning-9 {
  --background: rgba(var(--ion-color-warning-rgb), 0.9);
  background: rgba(var(--ion-color-warning-rgb), 0.9);
}
.bg-danger-9 {
  --background: rgba(var(--ion-color-danger-rgb), 0.9);
  background: rgba(var(--ion-color-danger-rgb), 0.9);
}
.bg-dark-9 {
  --background: rgba(var(--ion-color-dark-rgb), 0.9);
  background: rgba(var(--ion-color-dark-rgb), 0.9);
}
.bg-medium-9 {
  --background: rgba(var(--ion-color-medium-rgb), 0.9);
  background: rgba(var(--ion-color-medium-rgb), 0.9);
}
.bg-light-9 {
  --background: rgba(var(--ion-color-light-rgb), 0.9);
  background: rgba(var(--ion-color-light-rgb), 0.9);
}

.color-primary-9 {
  color: rgba(var(--ion-color-primary), 0.9);
}
.color-secondary-9 {
  color: rgba(var(--ion-color-secondary), 0.9);
}
.color-tertiary-9 {
  color: rgba(var(--ion-color-tertiary), 0.9);
}
.color-danger-9 {
  color: rgba(var(--ion-color-danger), 0.9);
}
.color-success-9 {
  color: rgba(var(--ion-color-success), 0.9);
}
.color-warning-9 {
  color: rgba(var(--ion-color-warning), 0.9);
}
.color-dark-9 {
  color: rgba(var(--ion-color-dark), 0.9);
}
.color-medium-9 {
  color: rgba(var(--ion-color-medium), 0.9);
}
.color-light-9 {
  color: rgba(var(--ion-color-light), 0.9);
}

.outline-dark-9 {
  border-color: rgba(var(--ion-color-dark), 0.9);
}

.outline-danger-9 {
  border-color: rgba(var(--ion-color-danger-rgb), 0.9);
}

.outline-success-9 {
  border-color: rgba(var(--ion-color-success-rgb), 0.9);
}

.outline-tertiary-9 {
  border-color: rgba(var(--ion-color-tertiary-rgb), 0.9);
}

.outline-medium-9 {
  border-color: rgba(var(--ion-color-medium-rgb), 0.9);
}

.outline-danger {
  border-color: var(--ion-color-danger);
}

.outline-primary {
  border-color: var(--ion-color-primary);
}

:root {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  letter-spacing: 1.5px;
}

.primary {
  color: var(--ion-color-primary);
}

.bg {
  --background: var(--ion-color-light);
}

.bg-transparent {
  background: transparent !important;
}

.glass-morp {
  backdrop-filter: blur(0.8rem);
}

.item-no-left-padding {
  --padding-start: 0;
}

ion-backdrop.sc-ion-modal-md.md.backdrop-no-tappable {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.toast {
  --max-width: 35rem;
}

.vHidden {
  visibility: hidden;
}

.modal-close ion-fab-button {
  --box-shadow: none;
}
.modal-close ion-fab-button::part(native) {
  background: transparent;
}

.sc-ion-modal-ios-h {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.transparent-input {
  --background: rgb(255 255 255 / 10%);
  --border-color: rgb(255 255 255 / 45%);
  margin-bottom: 1rem;
}
.transparent-input.item-has-focus {
  outline: 0.1rem solid rgba(255, 255, 255, 0.45);
  padding: 0;
}
.transparent-input ion-label {
  color: white !important;
  opacity: 0.8;
}
.transparent-input ion-input,
.transparent-input ion-select {
  --placeholder-color: white;
  --color: white;
  color: white;
}
.transparent-input ion-icon {
  color: white;
}

.white-button {
  --background: #fff;
  --color: var(--ion-color-primary);
  --box-shadow: 0 0 .6rem rgb(0 0 0 / 15%);
}

.no-shadow {
  box-shadow: none;
  --box-shadow: none;
}

.pointer {
  cursor: pointer;
}

.radius,
.rounded {
  border-radius: 0.5rem;
}

.rounded-modal .modal-wrapper {
  border-radius: 0.8rem;
}

.full-width {
  width: 100%;
}

.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: .8rem;
}

.edit-note-model {
  --width: 31.5rem;
  --height: 20rem;
}

.scrlItem {
  opacity: 0;
  transform: scale(0.5);
  transition: 0.5s ease all;
}

.scrlItem.active-on-scroll {
  opacity: 1;
  transform: scale(1);
}

.app-success-status {
  background: rgba(var(--ion-color-success-rgb), 0.1);
}
.app-success-status ion-note {
  color: rgba(var(--ion-color-success-rgb), 0.7);
}

.app-warning-status {
  background: rgba(var(--ion-color-warning-rgb), 0.1);
}
.app-warning-status ion-note {
  color: rgba(var(--ion-color-warning-rgb), 0.7);
}

.app-menu-options .popover-content {
  max-width: 20rem;
  padding: 0 1rem;
}

.notification-popup::part(content) {
  width: 80%;
  max-width: 35rem;
}
.notification-popup .popover-content {
  padding: 0 1rem;
}

.send-notification-popup .modal-wrapper {
  height: auto;
  max-height: 80%;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.back-btn {
  margin-right: -1.6rem !important;
}

.btn-custom {
  background-color: rgba(var(--ion-color-medium-rgb), 0.3);
  text-transform: none;
  height: unset !important;
  border-radius: 0.4rem;
}
.btn-custom::part(native) {
  padding: 0.8rem 1rem;
}
.btn-custom .flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.4rem;
}
.btn-custom .flex small {
  font-weight: 500;
  letter-spacing: 0.2rem;
  opacity: 0.7;
  margin-bottom: 0.3rem;
}
.btn-custom .txt {
  display: block;
}

.spin {
  animation: spin-animation 1.5s linear infinite;
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/** Media Queries **/
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.custom-scrollbar, .custom-scrollbar::part(scroll) {
  overflow: auto;
}
.custom-scrollbar ::-webkit-scrollbar, .custom-scrollbar::part(scroll) ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  left: -10px;
  position: relative;
}
.custom-scrollbar::-webkit-scrollbar-thumb, .custom-scrollbar::part(scroll)::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}
.custom-scrollbar::-webkit-scrollbar-track, .custom-scrollbar::part(scroll)::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0;
}
.custom-scrollbar:hover::-webkit-scrollbar-thumb, .custom-scrollbar::part(scroll):hover::-webkit-scrollbar-thumb {
  background: rgba(var(--ion-color-dark-rgb), 0.5);
}
.custom-scrollbar:hover::-webkit-scrollbar-track, .custom-scrollbar::part(scroll):hover::-webkit-scrollbar-track {
  background: rgba(var(--ion-color-dark-rgb), 0.1);
}

@media (max-width: 767px) {
  .custom-scrollbar {
    -webkit-overflow-scrolling: touch;
  }

  .custom-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-track {
    display: none;
  }

  .custom-scrollbar::-webkit-scrollbar {
    position: static;
  }
}
ion-button {
  font-size: 1.3rem;
  height: 3.6rem;
}

ion-back-button {
  font-size: 1.4rem;
}
ion-back-button::part(icon) {
  font-size: 2.4rem;
}

ion-title {
  font-size: 2rem;
}

ion-item {
  font-size: 1.6rem;
}

ion-icon {
  font-size: 2.4rem;
}

.main-grid {
  min-height: 100%;
  height: -webkit-fill-available;
  width: 100%;
}
.main-grid ion-back-button {
  display: block;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  top: 10px;
  left: 10px;
  color: var(--ion-color-dark);
}
.main-grid ion-back-button::part(text) {
  display: none;
}
.main-grid ion-row {
  min-height: -webkit-fill-available;
}

.box-wrapper {
  padding: 20px 50px;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
}
.box-wrapper ion-card-header {
  text-align: center;
}
.box-wrapper ion-card-header .restaurant-logo {
  background: linear-gradient(45deg, #bdbdbd, #ebebeb);
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 0 auto 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.box-wrapper ion-card-header .restaurant-logo img {
  max-width: 130px;
  margin-bottom: 0;
}
.box-wrapper ion-card-header img {
  width: 90%;
  height: auto;
  max-width: 250px;
  margin-bottom: 3rem;
}
.box-wrapper ion-card-header ion-card-title {
  font-size: 26px;
  font-weight: 600;
  display: block;
  margin: 0 0 4px;
}
.box-wrapper ion-card-header ion-card-subtitle {
  font-size: 20px;
  font-weight: 300;
  text-transform: none;
}
.box-wrapper ion-card-header p {
  font-size: 1.5rem;
  max-width: 230px;
  text-align: center;
  margin: 0 auto 1rem;
  opacity: 0.6;
  line-height: 1.2;
  font-weight: 500;
}
.box-wrapper ion-card-header small {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-color-dark);
}
.box-wrapper .button {
  width: 100%;
  margin: 0 auto 2rem;
  height: 45px;
  min-height: 45px;
  text-transform: none;
}
.box-wrapper .button::part(native) {
  box-shadow: none;
  border-radius: 10px;
  height: 100%;
}
.box-wrapper .button.btn::part(native) {
  color: var(--ion-color-dark);
}
.box-wrapper form .item {
  margin-bottom: 2rem;
}
.box-wrapper .secondary-action {
  text-align: center;
}

@media (max-width: 767px) {
  .fullH-mob {
    height: 100%;
  }

  .box-wrapper {
    height: 100%;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: none;
    background: transparent;
  }
}